import React from 'react';
import { graphql } from 'gatsby';
import { OutboundLink as Anchor } from 'gatsby-plugin-google-analytics';
import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { Helmet } from 'react-helmet';

const Icon = styled('img')``;
const Link = styled(Anchor)``;

const PillarAsset = ({ className, data: { post } }) => {
  const [author] = post.authors || [];
  const url = `https://joined.app/pillar-assets`;

  const uri = {
    title: encodeURIComponent(post.metaTitle || post.title),
    url: encodeURIComponent(url),
  };

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
          img[itemprop="logo"] {
            max-width: none;
          }
          nav > a {
            font-family: 'Maison Neue', sans-serif;
            font-size: 1.4rem;
            letter-spacing: 0;
            line-height: 1.15;
            font-weight: 500;
          }

          nav > a:hover  {
            text-decoration: none;
          }

         
          figure {
            text-align: center;
          }

          `}
        </style>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <div className={className}>
        <div className="container">
          <article className="post-full post tag-ecommerce tag-startup tag-shopify tag-amazon tag-tim-ferriss tag-d2c ">
            <header className="post-full-header">
              <section className="post-full-tags">
                <FormattedMessage id="SHARE" uppercase>
                  Share
                </FormattedMessage>
                <Link
                  href={`https://www.linkedin.com/shareArticle?mini=true&summary=${uri.title}&title=${uri.title}&url=${uri.url}`}
                  rel="noopener"
                  target="_blank"
                >
                  <Icon
                    alt={post.title}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    style={{ backgroundImage: `url("/images/linkedin.svg")` }}
                  />
                </Link>

                <Link
                  href={`https://www.facebook.com/sharer/sharer.php?u=${uri.url}`}
                  rel="noopener"
                  target="_blank"
                >
                  <Icon
                    alt={post.title}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    style={{ backgroundImage: `url("/images/facebook.svg")` }}
                  />
                </Link>
                <Link
                  href={`https://twitter.com/share?text=${uri.title}&url=${uri.url}`}
                  rel="noopener"
                  target="_blank"
                >
                  <Icon
                    alt={post.title}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    style={{ backgroundImage: `url("/images/twitter.svg")` }}
                  />
                </Link>
              </section>
              <h1 className="post-full-title">{post.title}</h1>
              <p className="post-full-custom-excerpt">{post.excerpt}</p>
              <div className="post-full-byline">
                <section className="post-full-byline-content">
                  <ul className="author-list">
                    <li className="author-list-item">
                      <div className="author-card">
                        <img
                          className="author-profile-image"
                          src={author.profile_image}
                          alt={author.name}
                        />
                        <div className="author-info">
                          <h2>{author.name}</h2>
                          <p>
                            Read <a href={author.website}>more posts</a> by this
                            author.
                          </p>
                        </div>
                      </div>
                      <a href={author.website} className="author-avatar">
                        <img
                          className="author-profile-image"
                          src={author.profile_image}
                          alt={author.name}
                        />
                      </a>
                    </li>
                  </ul>
                  <section className="post-full-byline-meta">
                    <h4 className="author-name">
                      <a href={author.website}>{author.name}</a>
                    </h4>
                    <div className="byline-meta-content">
                      <time
                        className="byline-meta-date"
                        dateTime={post.published_at}
                      >
                        {post.published_at_pretty}
                      </time>
                      <span className="byline-reading-time">
                        <span className="bull">•</span> {post.reading_time} min
                        read
                      </span>
                    </div>
                  </section>
                </section>
              </div>
            </header>
            {post.feature_image ? (
              <figure className="post-feature-image">
                <img src={post.feature_image} alt={post.title} />
              </figure>
            ) : null}
            <section className="post-full-content">
              <section
                className="content-body load-external-scripts"
                dangerouslySetInnerHTML={{ __html: post.html }} // eslint-disable-line
              />
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

PillarAsset.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    post: PropTypes.object,
  }),
};

export const query = graphql`
  query($pillarAssetsId: String!) {
    post: ghostPost(id: { eq: $pillarAssetsId }) {
      ...GhostPostFields
    }
  }
`;

export default styled(PillarAsset)`
  margin: 2.4rem;

  ${Icon} {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin-left: 0.8rem;
    height: 2rem;
    width: 2rem;

    &:first-of-type {
      margin-left: 1.6rem;
    }
  }
`;
